.header {
  height: 62px;

  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  /* border-radius: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 7px 14px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.header p {
  font-size: 24px;
  color: #646464;
  font-weight: 800;
  margin-top: -5px;
}

.header span {
  font-size: 12px;
  color: #646464;
  font-weight: 800;
}

.sender {
  height: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  /* border-radius: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 14px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sender input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;

  font-size: 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.messages {
  width: 100%;
  height: calc(100% - 112px);
  max-height: calc(100% - 112px);
  overflow-y: auto;
  padding-bottom: 14px;
}

.bubble {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 14px;
  margin-top: 16px;
}

.bubble p {
  width: fit-content;
  background: red;
  padding: 12px 18px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  font-size: 16px;
  max-width: 75%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.send-btn {
  width: 30px;
  cursor: pointer;
}

.chatbot {
  animation-name: slide-up;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes slide-up {
  from {
    margin-top: 100%;
  }
  to {
    margin-top: 0;
  }
}
